import axios from '@/services/axios';
import type {GetListingsParams, GetListingsResponse} from '../types';

const getAllListings = (params: GetListingsParams) => {
  return axios<GetListingsResponse>({
    url: '/properties/active_properties',
    method: 'get',
    params,
  });
};

export default getAllListings;

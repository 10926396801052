export const BookingStatusLabels = {
  1: 'pending',
  2: 'confirmed',
  3: 'declined',
} as const;

export const BookingStatusIds = {
  pending: 1,
  confirmed: 2,
  declined: 3,
  cancelledByListing: 4,
  cancelledByBuyer: 5,
  cancelledByOccupants: 6,
} as const;

import {useInfiniteQuery} from '@tanstack/react-query';
import getAllListings from '../services/getAllListings';
import {GetListingsParams} from '../types';

const useGetAllListings = (params: GetListingsParams) => {
  return useInfiniteQuery({
    queryKey: [
      'listings',
      {
        ...params,
      },
    ],
    queryFn: ({pageParam = 0}) =>
      getAllListings({
        ...params,
        offset: pageParam,
      }),
  });
};

export default useGetAllListings;

import {AnchorHTMLAttributes, forwardRef} from 'react';
import {ParagraphSizes} from '@/theme/fonts/types';
import {Slot} from '@radix-ui/react-slot';
import {cva, cx} from 'class-variance-authority';
import styles from './styles.module.css';

type LinkProps = {
  underline?: boolean;
  variant?: 'monochrome' | 'brand' | 'neutral';
  fontSize?: keyof ParagraphSizes;
  asChild?: boolean;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

const linkVariants = cva(styles.base, {
  variants: {
    variant: {
      monochrome: styles.monochrome,
      brand: styles.brand,
      neutral: styles.neutral,
    },
    fontSize: {
      p_xsmall: styles.xs,
      p_small: styles.sm,
      p_main: styles.main,
      p_medium: styles.md,
      p_large: styles.lg,
      p_xlarge: styles.lg,
    },
    underline: {
      true: styles.underline,
      false: styles.noUnderline,
    },
  },
});

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      variant = 'brand',
      underline = false,
      fontSize = 'p_main',
      asChild,
      className,
      ...props
    },
    forwardedRef,
  ) => {
    const Comp = asChild ? Slot : 'a';

    return (
      <Comp
        ref={forwardedRef}
        className={cx(linkVariants({variant, underline, fontSize}), className)}
        {...props}
      />
    );
  },
);

Link.displayName = 'Link';

export default Link;

import {ModalBody} from '@/components/ui/Modal';
import styled from 'styled-components';
import {ModalContent as BaseModalContent} from '@/components/ui/Modal';

export const ModalContent = styled(BaseModalContent)`
  width: 100%;
  max-width: 640px;
`;

export const Wrapper = styled(ModalBody)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: ${({theme}) => theme.borders.desktop.border_radius.main}px;
  text-align: center;
  gap: ${({theme}) => theme.spacing.mobile.element.gap.xlarge}px;
  padding: ${({theme}) => theme.spacing.mobile.element.pad.xxlarge}px;

  ${({theme}) => theme.media.mediaQueries.sm} {
    gap: ${({theme}) => theme.spacing.tablet.element.gap.xlarge}px;
    padding: ${({theme}) => theme.spacing.tablet.element.pad.xxlarge}px;
  }

  ${({theme}) => theme.media.mediaQueries.md} {
    gap: ${({theme}) => theme.spacing.desktop.element.gap.xlarge}px;
    padding: ${({theme}) => theme.spacing.desktop.element.pad.xxlarge}px;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: left;
  border-radius: ${({theme}) => theme.borders.desktop.border_radius.small}px;
  border: 1px solid ${({theme}) => theme.colors.border_color_subdued};
  padding: ${({theme}) => theme.spacing.mobile.element.pad.medium}px
    ${({theme}) => theme.spacing.mobile.element.pad.xlarge}px;
  gap: ${({theme}) => theme.spacing.mobile.element.gap.small}px;

  ${({theme}) => theme.media.mediaQueries.sm} {
    padding: ${({theme}) => theme.spacing.tablet.element.pad.medium}px
      ${({theme}) => theme.spacing.tablet.element.pad.xlarge}px;
    gap: ${({theme}) => theme.spacing.tablet.element.gap.small}px;
  }

  ${({theme}) => theme.media.mediaQueries.md} {
    padding: ${({theme}) => theme.spacing.desktop.element.pad.medium}px
      ${({theme}) => theme.spacing.desktop.element.pad.xlarge}px;
    gap: ${({theme}) => theme.spacing.desktop.element.gap.small}px;
  }
`;

export const InfoItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${({theme}) => theme.spacing.mobile.element.gap.xsmall}px;

  ${({theme}) => theme.media.mediaQueries.sm} {
    gap: ${({theme}) => theme.spacing.tablet.element.gap.xsmall}px;
  }

  ${({theme}) => theme.media.mediaQueries.md} {
    gap: ${({theme}) => theme.spacing.desktop.element.gap.xsmall}px;
  }
`;

import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-top: 136px;
`;

export const SearchWrapper = styled.div`
  padding: 40px 0;
  top: 10px;
  width: 100vw;
  right: 0;
  left: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    width: 1440px;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  width: 100%;
  border-bottom: 1px solid ${({theme}) => theme.new_brand.monochrome_90};
`;

export const CardWrapper = styled.section`
  background-color: ${({theme}) => theme.new_brand.monochrome_100};
`;

export const GridCardWrapper = styled.section`
  display: grid;
  width: 100%;
  grid-gap: 16px;
  margin-bottom: 80px;
  grid-template-columns: 32.5% 32.5% 32.5%;

  @media screen and (max-width: 1080px) {
    grid-template-columns: 49% 49%;
    grid-row-gap: 40px;
  }

  @media screen and (max-width: 680px) {
    grid-template-columns: 100%;
  }
`;

import {useEffect, useMemo, useRef, useState} from 'react';
import {debounce} from 'lodash-es';
import {SORT_BY_OPTIONS} from '@/constants';
import useLocalStorageState from 'use-local-storage-state';
import useGetAllListings from '@/features/listings/hooks/useGetAllListings';
import {useDebounce} from '@/hooks/useDebounce';

export const useData = () => {
  const [value, setValue] = useState('');
  const offset = useRef(0);

  const [filter] = useLocalStorageState<string[]>('ALL_LISTINGS_FILTER');
  const allStatuses = 'Active,ActiveUnderContract,Pending,Closed';
  const statusFilter = useMemo(() => {
    return filter?.join(',');
  }, [filter]);

  const [sortType, setSortType] = useState<number>(0);

  const search = useDebounce(value);

  const {
    data: allListingsData,
    isFetching,
    isFetchingNextPage,
    fetchNextPage: allListingsNextPage,
  } = useGetAllListings({
    search,
    std_status__in: statusFilter || allStatuses,
    ordering: SORT_BY_OPTIONS[sortType],
  });

  const isLoading = isFetching || isFetchingNextPage;
  const listings = allListingsData?.pages;

  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const scrollHeight = document.documentElement.scrollHeight;
    const hasLastPage =
      !!allListingsData?.pages[allListingsData?.pages.length - 1]?.data.next;
    if (scrollTop + windowHeight >= scrollHeight && !isLoading && hasLastPage) {
      const newOffset = offset.current + 20;
      offset.current = newOffset;
      allListingsNextPage({pageParam: newOffset});
    }
  };

  useEffect(() => {
    const debouncedHandleScroll = debounce(handleScroll, 200);

    window.addEventListener('scroll', debouncedHandleScroll);

    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, [isLoading]);

  const handleSortChange = (newSortType: number) => {
    setSortType(newSortType);
  };

  return {
    listings,
    setValue,
    isLoading,
    statusFilter,
    allListingsNextPage,
    handleSortChange,
    sortType,
  };
};

import {Suspense, lazy} from 'react';
import {
  InfoItem,
  InfoWrapper,
  ModalContent,
  Wrapper,
} from './ShowingRequested.style';
import {
  Modal,
  ModalCloseTrigger,
  ModalProps as UIModalProps,
} from '@/components/ui/Modal';
import Heading from '@/components/ui/Heading';
import Button from '@/components/ui/Button';
import calendarAnimation from '@/assets/images/Showings/calendar_animation.json';
import Text from '@/components/ui/Text';
import {
  Briefcase,
  Clock,
  DoorOpen,
  MapPinLine,
  Users,
} from '@phosphor-icons/react';
import Link from '@/components/ui/Link';
import Skeleton from '@/components/ui/Skeleton';
import {format, parse} from 'date-fns';

const Lottie = lazy(() => import('lottie-react'));

type ModalProps = {
  address: string;
  listingKey: string;
  date: string;
  time: {from: string; to: string};
  buyers: {firstName?: string; lastName?: string; email?: string | null}[];
  listingAgent: {firstName: string; lastName: string};
  type: string;
  isConfirmed: boolean;
} & UIModalProps;

const ShowingRequested = ({
  address,
  date,
  time,
  buyers,
  listingKey,
  listingAgent,
  type,
  isConfirmed,
  ...props
}: ModalProps) => {
  const options = {
    animationData: calendarAnimation,
    autoplay: true,
    loop: false,
  };

  const parsedDate = parse(date, 'MM.dd.yyyy', new Date());
  const formattedDate = format(parsedDate, 'EEEE, MMMM d, yyyy');

  return (
    <Modal {...props}>
      <ModalContent>
        <Wrapper>
          <Suspense fallback={<Skeleton width={200} height={200} />}>
            <Lottie {...options} />
          </Suspense>
          <Heading size="h3" weight="bold" as="h2">
            Showing {isConfirmed ? 'confirmed' : 'requested'}
          </Heading>
          {!isConfirmed ? (
            <Text weight="medium" size="p_small" color="font_color_subdued">
              Waiting for confirmation from the listing agent and occupants of
              the property.
            </Text>
          ) : null}
          <InfoWrapper>
            <InfoItem>
              <MapPinLine size="20" />
              <Text>:</Text>
              <Link href={`/listings/${listingKey}`}>{address}</Link>
            </InfoItem>{' '}
            <InfoItem>
              <DoorOpen size="20" />
              <Text>:</Text>
              {type}
            </InfoItem>
            <InfoItem>
              <Clock size="20" />
              <Text>:</Text>
              <Text weight="bold">
                {time.from} - {time.to}, {formattedDate}
              </Text>
            </InfoItem>
            <InfoItem>
              <Briefcase size="20" />
              <Text>:</Text>
              Listing agent: {listingAgent.firstName} {listingAgent.lastName}
            </InfoItem>
            <InfoItem>
              <Users size="20" />
              <Text>:</Text>
              <div>
                {buyers.map(({firstName, lastName, email}, id) => {
                  return (
                    <Text asTag="p" key={`buyer-${id}`}>
                      {firstName} {lastName}
                      {firstName || lastName ? ',' : ''} {email}
                    </Text>
                  );
                })}
              </div>
            </InfoItem>
          </InfoWrapper>
          <ModalCloseTrigger asChild>
            <Button size="small" variant="secondary_mono_dark">
              Close
            </Button>
          </ModalCloseTrigger>
        </Wrapper>
      </ModalContent>
    </Modal>
  );
};

export default ShowingRequested;

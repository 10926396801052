import {ErrorIcon, EyeIcon, WarningIcon} from '@/components/Icons';
import {FontWeights} from '@/themes';
import {InputKeys} from '@/themes/palletes/types';
import {
  FC,
  InputHTMLAttributes,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {FieldError} from 'react-hook-form';
import {
  Label,
  Wrapper,
  ErrorText,
  Icon,
  Input,
  InputWrapper,
  Content,
  PasswordShowWrapper,
  CustomText,
} from './FilledField.styles';

type TProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  rightIcon?: ReactNode;
  leftIcon?: ReactNode;
  error?: FieldError;
  color?: keyof InputKeys;
  weight?: keyof typeof FontWeights;
  width?: string;
  size?: 'default' | 'big' | 'small' | 'bigger';
  variant?: 'square' | 'round';
  margins?: {
    top?: string;
    left?: string;
    right?: string;
    bottom?: string;
  };
  password?: boolean;
  isDisabled?: boolean;
  customPlaceholder?: string;
  withoutEye?: boolean;
  backErrors?: string | string[] | null;
  isSingleError?: boolean;
  date?: boolean;
  number?: boolean;
  required?: boolean;
  missed?: boolean;
  onClick?: () => void;
  clearError?: () => void;
};

export const FilledField: FC<TProps> = ({
  label,
  error,
  backErrors,
  isSingleError,
  leftIcon,
  rightIcon,
  date,
  weight = 'regular',
  margins,
  variant = 'square',
  size = 'default',
  color = 'default',
  width = '100%',
  password,
  isDisabled,
  customPlaceholder = '',
  withoutEye,
  number,
  required,
  missed,
  onClick,
  clearError = () => {},

  ...props
}) => {
  const [hidden, setHidden] = useState(true);

  const margin = useMemo(
    () => ({
      top: margins?.top || '0',
      bottom: margins?.bottom || '0',
      right: margins?.right || '0',
      left: margins?.left || '0',
    }),
    [margins],
  );

  const height = useMemo(() => {
    switch (size) {
      case 'default':
        return 56;
      case 'big':
        return 120;
      case 'small':
        return 40;
      case 'bigger':
        return 44;
      default:
        return 44;
    }
  }, [size]);

  const borderRadius = useMemo(() => {
    switch (variant) {
      case 'square':
        return 6;
      case 'round':
        return 40;
    }
  }, [variant]);
  useEffect(() => {
    clearError();
  }, [props.value]);
  useEffect(() => {
    isSingleError && clearError();
  }, [error]);
  const [custom, setCustom] = useState(false);
  return (
    <Wrapper
      width={width}
      margin={margin}
      style={{opacity: isDisabled ? '0.5' : '1'}}>
      {label && <Label>{label}</Label>}
      <Content
        onClick={onClick}
        color={error || !!backErrors ? 'accent_darker' : color}
        missed={missed}
        height={height}
        borderRadius={borderRadius}>
        {leftIcon && <Icon>{leftIcon}</Icon>}

        <InputWrapper>
          {customPlaceholder && (
            <CustomText
              moveToTop={custom || !!props.value}
              color={color}
              date={date}
              style={{
                paddingTop:
                  custom || !!props.value ? (date ? '18px' : '10px') : '0px',
              }}
              error={!!error?.message || !!backErrors?.[0]}>
              {customPlaceholder}
              {required && <span style={{color: 'red'}}> *</span>}
            </CustomText>
          )}
          <Input
            {...props}
            color={error || !!backErrors ? 'default' : color}
            weight={weight}
            style={{
              paddingTop:
                customPlaceholder && (custom || !!props.value) ? '14px' : '0px',
            }}
            onFocus={() => setCustom(true)}
            onBlur={() => setCustom(false)}
            type={
              password && hidden
                ? 'password'
                : date
                  ? 'date'
                  : number
                    ? 'number'
                    : 'text'
            }
            readOnly={isDisabled}
          />
          {!!password && !withoutEye && (
            <PasswordShowWrapper onClick={() => setHidden(!hidden)}>
              <EyeIcon opacity={hidden ? 0.5 : 1} />
            </PasswordShowWrapper>
          )}
        </InputWrapper>

        {rightIcon && <Icon>{rightIcon}</Icon>}
        {(error || backErrors) && <ErrorIcon />}
        {!error && !backErrors && missed && <WarningIcon />}
      </Content>
      {error?.message && (
        <ErrorText
          style={{
            position: error.message ? 'relative' : 'absolute',
          }}>
          {error?.message || ''}
        </ErrorText>
      )}
      {backErrors &&
        (typeof backErrors == 'string' ? (
          <ErrorText>
            {backErrors == 'custom user with this Email Address already exists.'
              ? 'A user with this email address already exists'
              : backErrors}
          </ErrorText>
        ) : (
          <ErrorText>
            {backErrors[0] ==
            'custom user with this Email Address already exists.'
              ? 'A user with this email address already exists'
              : backErrors[0] || ''}
          </ErrorText>
        ))}
    </Wrapper>
  );
};

import {useEffect, useState} from 'react';

export const useDebounce = <T>(
  value: T,
  callback?: () => void,
  delay?: number,
  isLoading?: boolean,
) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      !isLoading && setDebouncedValue(value);
      callback && callback();
    }, delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay, isLoading]);

  return debouncedValue;
};

import type {PropsWithChildren} from 'react';
import styles from './styles.module.css';
import {cx} from 'class-variance-authority';

type ContainerProps = React.HTMLAttributes<HTMLDivElement> & PropsWithChildren;

const Container = ({children, className, ...props}: ContainerProps) => {
  return (
    <div className={cx(styles.wrapper, className)} {...props}>
      {children}
    </div>
  );
};

export default Container;

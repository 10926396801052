import styled from 'styled-components';

export const Wrapper = styled.section`
  padding-bottom: 80px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  ${({theme}) => theme.mediaQueriesMax.xs} {
    padding-top: 16px;
    gap: 24px;
  }
`;

export const TopSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 40px;
  ${({theme}) => theme.mediaQueriesMax.md} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
`;

export const SearchSection = styled.section`
  ${({theme}) => theme.mediaQueries.sm} {
    display: flex;
    gap: ${({theme}) => theme.spacing.m}px;
    justify-content: space-between;
    align-items: center;
  }
`;

export const TabsTitle = styled.span`
  display: none;

  ${({theme}) => theme.mediaQueries.xs} {
    display: inline;
  }
`;

export const SortWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  padding-top: 32px;
  border-top: 1px solid ${({theme}) => theme.new_brand.monochrome_90};

  ${({theme}) => theme.mediaQueriesMax.sm} {
    gap: 8px;
  }
`;

export const GridCardWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-bottom: 80px;

  ${({theme}) => theme.mediaQueriesMax.md} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${({theme}) => theme.mediaQueriesMax.sm} {
    grid-template-columns: 1fr;
  }
`;

export const ListCardWrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

export const SwitcherWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ArchiveButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid #999;
  border-radius: 6px;
  margin-left: 8px;
  cursor: pointer;
`;

export const ArchiveWrapper = styled.section`
  border-top: 1px solid ${({theme}) => theme.new_brand.monochrome_90};
  padding: 24px 0;
`;

export const EmptyWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  text-align: left;
  align-items: center;

  ${({theme}) => theme.mediaQueriesMax.sm} {
    flex-flow: column nowrap;
    align-items: flex-start;
  }
`;

export const EmptyTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  width: 100%;

  ${({theme}) => theme.mediaQueriesMax.xs} {
    font-size: 16px;
  }
`;

export const EmptyBody = styled.div`
  order: 3;
  flex: 1;
  margin-top: 8px;

  ${({theme}) => theme.mediaQueriesMax.xs} {
    font-size: 12px;
  }

  ${({theme}) => theme.mediaQueriesMax.sm} {
    margin-top: 0;
    order: 0;
  }
`;

export const SortByItem = styled.div<{first?: boolean; last?: boolean}>`
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: ${({first, last}) =>
    `${first ? '8px' : '6px'} 40px ${last ? '8px' : '6px'} 16px`}!important;
`;

export const SuccessModalWrapper = styled.div`
  width: 100%;
  max-width: 415px;
  border-radius: 16px;
  background-color: ${({theme}) => theme.new_brand.monochrome_100};
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  padding: 0 40px 24px;

  & svg {
    width: 250px;
    height: 250px;
  }
`;

export const OfferSeenText = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  padding: 16px 0 40px;
`;

export const EmptyButtonsWrapper = styled.div`
  display: inline-flex;
  margin-top: 8px;
`;

export const LeftButtons = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const RefreshButton = styled.button`
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 1px solid ${(props) => props.theme.new_brand.monochrome_75};
  background: ${(props) => props.theme.new_brand.monochrome_100};
  cursor: default;

  &:not(:disabled) {
    cursor: pointer;
  }

  &:hover {
    background: ${(props) => props.theme.new_brand.monochrome_95};
  }

  &:disabled {
    background: ${(props) => props.theme.new_brand.monochrome_100};
    opacity: 0.65;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const TooltipWrapper = styled.div`
  position: relative;

  &:hover span {
    display: flex;
    margin-top: 4px;
  }
`;

export const Tooltip = styled.span`
  padding: 8px;
  border-radius: 6px;
  background-color: ${({theme}) => theme.new_brand.monochrome_100};
  display: none;
  position: absolute;
  box-shadow: 0 0 16px rgb(0 0 0 / 16%);
  cursor: pointer;
  color: ${({theme}) => theme.new_brand.monochrome_50};
  width: max-content;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100%);
  z-index: 1000;
`;

export const RightWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: ${({theme}) => theme.spacing.desktop.element.gap.xsmall};
  max-width: 448px;
`;

import {
  Wrapper,
  Image,
  Body,
  Skeleton,
  Title,
  Meta,
} from './SkeletonCard.style';

export const SkeletonCard = () => {
  return (
    <Wrapper>
      <Image>
        <Skeleton $height="225px" $width="100%"></Skeleton>
      </Image>
      <Body>
        <Title>
          <Skeleton $height="16px" $width="40%"></Skeleton>
          <Skeleton $height="16px" $width="100%"></Skeleton>
        </Title>
        <Meta>
          <Skeleton $height="40px" $width="40%"></Skeleton>
          <Skeleton $height="40px" $width="60%"></Skeleton>
        </Meta>
        <Skeleton $height="16px" $width="90%"></Skeleton>
        <Skeleton $height="44px" $width="100%"></Skeleton>
      </Body>
    </Wrapper>
  );
};

import styled, {keyframes} from 'styled-components';
import {themes} from '@/themes';

const skeletonLoading = keyframes`
  0% {
    background-color: ${themes.light.pallete.new_brand.monochrome_90};
  }
  100% {
    background-color: ${themes.light.pallete.new_brand.monochrome_80};
  }
`;

export const Wrapper = styled.div`
  background-color: ${({theme}) => theme.new_brand.monochrome_100};
  border: 1px solid ${({theme}) => theme.new_brand.monochrome_75};
  border-radius: 16px;
`;

export const Image = styled.div`
  border-radius: 16px 16px 0 0;
  overflow: hidden;
`;

export const Body = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Meta = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

export const Skeleton = styled.div<{$width: string; $height: string}>`
  animation: ${skeletonLoading} 1s linear infinite alternate;
  border-radius: 4px;
  width: ${({$width}) => $width};
  height: ${({$height}) => $height};
`;
